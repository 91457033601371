import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const LazyCell = ({
  queryKey,
  additionalQueriesResults,
  rowId,
  renderContent,
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
}) => {
  const queryResult = useMemo(
    () => additionalQueriesResults?.[queryKey],
    [additionalQueriesResults, queryKey],
  );

  const { loading, error, data } = queryResult;

  if (loading) {
    return LoadingComponent ? <LoadingComponent /> : <CircularProgress size={20} />;
  }

  if (data) {
    return renderContent(data, rowId);
  }

  if (error) {
    return ErrorComponent ? (
      <ErrorComponent error={error} />
    ) : (
      <Typography color="error" variant="caption">
        Error loading data
      </Typography>
    );
  }

  return null;
};

LazyCell.propTypes = {
  queryKey: PropTypes.string.isRequired,
  additionalQueriesResults: PropTypes.shape({}),
  rowId: PropTypes.string.isRequired,
  renderContent: PropTypes.func.isRequired,
  loadingComponent: PropTypes.elementType,
  errorComponent: PropTypes.elementType,
};

LazyCell.defaultProps = {
  additionalQueriesResults: {},
  loadingComponent: null,
  errorComponent: null,
};

export default LazyCell;
