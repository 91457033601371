import React from 'react';
import PropTypes from 'prop-types';
import { filterByUniqueObjectsByKey } from '../../helpers/arrays';
import LazyCell from './LazyCell';
import ActionDrawerCell from './ActionDrawerCell';

const LazyActionDrawerCell = ({ rowId, name, queryKey, additionalQueriesResults,
  headerComponent, contentComponent, queryName }) => {
  const transformActionsData = (data) => {
    const edges = data?.[queryName]?.edges || [];
    const matchingEdge = edges.find((edge) => edge.node?.id === rowId);
    const pendingCollectionManagers = matchingEdge?.node?.pendingCollectionManagers || [];

    const actions = pendingCollectionManagers.reduce((allActions, manager) => {
      const managerActions = manager.actions || [];
      return [...allActions, ...managerActions];
    }, []);

    const uniqueActions = filterByUniqueObjectsByKey(actions, 'id');
    return uniqueActions.sort((a, b) => b.id - a.id);
  };

  return (
    <LazyCell
      key={`${rowId}`}
      queryKey={queryKey}
      additionalQueriesResults={additionalQueriesResults}
      rowId={rowId}
      renderContent={(data) => {
        const sortedActions = transformActionsData(data);
        return (
          <ActionDrawerCell
            actions={sortedActions}
            title={name}
            disabled={!sortedActions.length}
            headerComponent={headerComponent}
            contentComponent={contentComponent}
          />
        );
      }}
    />
  );
};

LazyActionDrawerCell.propTypes = {
  rowId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  queryKey: PropTypes.string.isRequired,
  queryName: PropTypes.string.isRequired,
  additionalQueriesResults: PropTypes.shape({}),
  headerComponent: PropTypes.elementType,
  contentComponent: PropTypes.elementType,
};

LazyActionDrawerCell.defaultProps = {
  additionalQueriesResults: {},
  headerComponent: null,
  contentComponent: null,
};

export default LazyActionDrawerCell;
