import React from 'react';
import PropTypes from 'prop-types';
import LazyCell from './LazyCell';
import ActionCell from './ActionCell';

const LazyActionCell = ({ rowId, queryKey, additionalQueriesResults, queryName }) => {
  const lastActionData = (data) => {
    const actions = data?.[queryName]?.edges
      .find((edge) => edge.node?.id === rowId)
      ?.node?.pendingCollectionManagers?.flatMap((mgr) => mgr.actions)
      .sort((a, b) => b.id - a.id);
    return actions ? actions[0] : null;
  };

  return (
    <LazyCell
      key={`${rowId}`}
      queryKey={queryKey}
      additionalQueriesResults={additionalQueriesResults}
      rowId={rowId}
      renderContent={(data) => {
        const lastAction = lastActionData(data);
        return <ActionCell action={lastAction} />;
      }}
    />
  );
};

LazyActionCell.propTypes = {
  rowId: PropTypes.string.isRequired,
  queryKey: PropTypes.string.isRequired,
  additionalQueriesResults: PropTypes.shape({}),
  queryName: PropTypes.string.isRequired,
};

LazyActionCell.defaultProps = {
  additionalQueriesResults: {},
};

export default LazyActionCell;
